import ProductsSliderWithPlaceholder from 'src/components/views/ProductsSliderWithPlaceholder/ProductsSliderWithPlaceholder';
import useProductsCarouselHandlers from 'src/hooks/useProductsCarouselHandlers/useProductsCarouselHandlers';
import selectAddress from 'src/redux/cart/general/selectAddress';
import useFavorites from 'src/services/favorites/useFavorites';
import useLocalization from 'src/services/localization/useLocalization';
import { addToCartButtonsEnum, impressionListEnum, sendRemarketingCategoryProducts, sendViewProductsListEvent } from 'src/utils/marketing/enhancedEcommerce';
import mapProductsWithAmount from 'src/utils/product/mapProductsWithAmount';
import { selectCartItems } from '../../../redux/cart/general/cartSelectors';
import { useAppSelector } from '../../../redux/hooks';
import mapViewProducts from '../../../utils/product/mapViewProducts';
import useSpecialProducts from './useSpecialProducts';

const place = addToCartButtonsEnum.SPECIAL_PRODUCTS_SLIDER;
const impressionPlace = impressionListEnum.MAIN_PAGE_SPECIAL_PRODUCTS;

export default function SpecialProductsSlider() {
  const localize = useLocalization();

  const { getProductIsFavorite } = useFavorites();
  const cartItems = useAppSelector(selectCartItems);
  const language = useAppSelector((s) => s.app.language);
  const userIsLoggedIn = useAppSelector((s) => s.user.isLoggedIn);
  const itemsCanBeAdded = useAppSelector((s) => !!s.app.cartType);
  const storeId = useAppSelector((s) => s.storeManagement.store.id);
  const deliveryType = useAppSelector(selectAddress).delivery;

  const {
    loadingStatus,
    specialProducts,
    updateSpecialProducts,
  } = useSpecialProducts();

  const {
    handleAddToCart,
    handleAmountChange,
    handleFavoriteClick,
    handleProductTileClick,
    handleProductTileImpressed,
  } = useProductsCarouselHandlers(place);

  const titleText = localize('special_products_title');

  const sliderItems = mapViewProducts(
    specialProducts,
    getProductIsFavorite,
    cartItems,
  );

  const handleImpressed = async () => {
    const specialProducts = await updateSpecialProducts(storeId, deliveryType);
    sendRemarketingCategoryProducts(specialProducts, impressionPlace, 'special');

    const productsWithAmount = mapProductsWithAmount(specialProducts, cartItems);
    sendViewProductsListEvent('special', titleText, productsWithAmount);
  };

  const placeholderShouldBeDisplayed = loadingStatus === 'idle' || loadingStatus === 'loading';
  const sliderShouldNotDisplayed =
    !userIsLoggedIn ||
    loadingStatus === 'failed' ||
    (loadingStatus === 'succeeded' && specialProducts.length < 5);

  return (
    <ProductsSliderWithPlaceholder
      placeholderTitle=''
      dataMarker='Special Products'
      onImpressed={handleImpressed}
      sliderShouldNotDisplayed={sliderShouldNotDisplayed}
      placeholderShouldBeDisplayed={placeholderShouldBeDisplayed}
      carouselProps={{
        title: titleText,
        items: sliderItems,
        productPlace: place,
        language: language,
        itemsCanBeAdded: itemsCanBeAdded,
        onProductTileClick: handleProductTileClick,
        onProductTileImpressed: handleProductTileImpressed,
        onAddToCart: handleAddToCart,
        onAmountChange: handleAmountChange,
        onFavoriteClick: handleFavoriteClick,
      }}
    />
  );
}
