import { useToggles } from "src/utils/toggles/useToggles";
import OffersWidget from "../OffersWidget/OffersWidget";
import OrdersWidget from "../OrdersWidget/OrdersWidget";
import ReferralProgramWidget from "../ReferralProgramWidget/ReferralProgramWidget";
import SlotsWidget from "../SlotsWidget/SlotsWidget";
import { styles } from "./MainPageWidgetsPanel.styles";

const MainPageWidgetsPanel = () => {
  const { REFERRAL_PROGRAM_AVAILABLE } = useToggles();
  return (
    <div className="MainPageWidgetsPanel" data-marker="widgets panel">
      <div className="MainPageWidgetsPanel__item MainPageWidgetsPanel__item_wide">
        <SlotsWidget />
      </div>
      <div className="MainPageWidgetsPanel__item">
        <OrdersWidget />
      </div>
      <div className="MainPageWidgetsPanel__item">
        <OffersWidget />
      </div>
      {
        REFERRAL_PROGRAM_AVAILABLE && (
          <div className="MainPageWidgetsPanel__item">
            <ReferralProgramWidget />
          </div>
        )
      }
      <style jsx>{styles}</style>
    </div>
  );
};

export default MainPageWidgetsPanel;
