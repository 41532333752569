import { useState } from 'react';
import { getLastViewProducts } from 'src/ajax/getLastViewProducts';
import { ProductsCarouselProps } from 'src/components/views/ProductsCarousel/ProductsCarousel';
import useProductsCarouselHandlers from 'src/hooks/useProductsCarouselHandlers/useProductsCarouselHandlers';
import useRequestStatus from 'src/hooks/useRequestStatus/useRequestStatus';
import { DeliveryType, Product } from 'src/redux/apiTypes';
import { selectCartItems } from 'src/redux/cart/general/cartSelectors';
import selectAddress from 'src/redux/cart/general/selectAddress';
import { useAppSelector } from 'src/redux/hooks';
import useFavorites from 'src/services/favorites/useFavorites';
import useLocalization from 'src/services/localization/useLocalization';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { addToCartButtonsEnum } from 'src/utils/marketing/enhancedEcommerce';
import mapViewProducts from 'src/utils/product/mapViewProducts';

const place = addToCartButtonsEnum.LAST_VIEW_PRODUCTS_SLIDER;

export default function useLastViewProductsProps() {
  const { fetchConfig } = useAppConfig();
  const localize = useLocalization();

  const { status, setStatus } = useRequestStatus();
  const [lastViewProducts, setLastViewProducts] = useState<Product[]>([]);

  const { getProductIsFavorite } = useFavorites();
  const cartItems = useAppSelector(selectCartItems);
  const language = useAppSelector((s) => s.app.language);
  const itemsCanBeAdded = useAppSelector((s) => !!s.app.cartType);
  const storeId = useAppSelector((s) => s.storeManagement.store.id);
  const deliveryType = useAppSelector(selectAddress).delivery;

  const {
    handleAddToCart,
    handleAmountChange,
    handleFavoriteClick,
    handleProductTileClick,
    handleProductTileImpressed,
  } = useProductsCarouselHandlers(place);

  const titleText = localize('recently_viewed');

  const sliderItems = mapViewProducts(
    lastViewProducts,
    getProductIsFavorite,
    cartItems,
  );

  const updateLastViewProducts = async (
    storeId: string,
    deliveryType: DeliveryType,
  ): Promise<Product[]> => {
    let res = {} as Response;

    try {
      setStatus('loading');
      res = await getLastViewProducts({
        storeId,
        fetchConfig,
        deliveryType,
      });
    } catch (error) {
      setStatus('failed');
      console.error(error);
      return [];
    }

    if (res.ok) {
      const data: Product[] = await res.json();

      setStatus('succeeded');
      setLastViewProducts(data);
      return data;
    } else {
      setStatus('failed');
      return [];
    }
  };

  const handleImpressed = async () => {
    await updateLastViewProducts(storeId, deliveryType);
  };

  const placeholderShouldBeDisplayed = status === 'idle' || status === 'loading';
  const sliderShouldNotDisplayed = status === 'failed' ||
    (status === 'succeeded' && lastViewProducts.length < 5);

  const carouselProps: ProductsCarouselProps = {
    title: titleText,
    items: sliderItems,
    productPlace: place,
    language: language,
    itemsCanBeAdded: itemsCanBeAdded,
    onProductTileClick: handleProductTileClick,
    onProductTileImpressed: handleProductTileImpressed,
    onAddToCart: handleAddToCart,
    onAmountChange: handleAmountChange,
    onFavoriteClick: handleFavoriteClick,
  };

  return {
    carouselProps,
    onImpressed: handleImpressed,
    sliderShouldNotDisplayed,
    placeholderShouldBeDisplayed,
  };
}
