import { DeliveryType, FetchConfig } from 'src/redux/apiTypes';

interface GetLastViewProductsParams {
  storeId: string;
  deliveryType: DeliveryType;
  fetchConfig: FetchConfig;
}

export async function getLastViewProducts(
  params: GetLastViewProductsParams,
): Promise<Response> {
  const { storeId, deliveryType, fetchConfig } = params;
  const { language, xChain, xVersion, apiRoot } = fetchConfig;

  const url = `${apiRoot}/stores/${storeId}/products/last_viewed/?count=20`;

  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-chain': xChain,
      'x-version': xVersion,
      'Accept-Language': language,
      'x-delivery-type': deliveryType,
    },
    credentials: 'include',
  };

  return await fetch(url, options);
}
