import { ReactNode } from "react";
import { selectOwnBrandsCount } from "src/redux/categories/categoriesSelectors";
import { useAppSelector } from "src/redux/hooks";
import { useProductsCount } from "src/swr/useProductsCount";
import { formatNumber } from "src/utils/js-helpers/formatNumber";
import { useToggles } from "src/utils/toggles/useToggles";
import { HaccpIcon, OwnBrandIcon, PriceIcon, ProductsIcon, WeightIcon } from "./BenefitIcons";
import useLocalization from "src/services/localization/useLocalization";

interface Benefit {
  icon: ReactNode;
  label: string;
  dataMarker?: string;
}

const useBenefits = (): Benefit[] => {
  const { CERTIFICATE_HACCP } = useToggles();
  const ownBrandsCount = useAppSelector(selectOwnBrandsCount);
  const productsCount = useProductsCount();
  const localize = useLocalization();

  const withOwnBrandsBenefit = ownBrandsCount > 0;

  const benefits: Benefit[] = [
    {
      icon: ProductsIcon,
      label: `${formatNumber(productsCount)} ${localize('zakazBenefits_assortment')}`,
      dataMarker: 'Products count',
    },
  ];


  if (withOwnBrandsBenefit) {
    benefits.push({
      icon: OwnBrandIcon,
      label: `${formatNumber(ownBrandsCount)} ${localize('zakazBenefits_ownBrand')}`,
      dataMarker: 'Own brands',
    });
  }

  if (CERTIFICATE_HACCP) {
    benefits.push({
      icon: HaccpIcon,
      label: localize('zakazBenefits_haccr'),
      dataMarker: 'HACCP',
    });
  }

  if (!(withOwnBrandsBenefit && CERTIFICATE_HACCP)) {
    benefits.push({
      icon: WeightIcon,
      label: localize('zakazBenefits_weight'),
      dataMarker: 'Weight',
    });
  }

  if (!withOwnBrandsBenefit && !CERTIFICATE_HACCP) {
    benefits.push({
      icon: PriceIcon,
      label: localize('zakazBenefits_price'),
      dataMarker: 'Price',
    });
  }

  return benefits;
};

export default useBenefits;
