import ProductsSliderWithPlaceholder from 'src/components/views/ProductsSliderWithPlaceholder/ProductsSliderWithPlaceholder';
import useLastViewProductsProps from './useLastViewProductsProps';

export default function LastViewProductsSlider() {
  const sliderProps  = useLastViewProductsProps();

  return (
    <ProductsSliderWithPlaceholder
      placeholderTitle=''
      dataMarker='Last View Products'
      {...sliderProps}
    />
  );
}
