import { NextPage } from 'next';
import { requestGetFiltersLikeCategoriesProducts } from 'src/modules/Categories';
import { Banner, CategoryProducts, Product } from 'src/redux/apiTypes';
import selectAddress from 'src/redux/cart/general/selectAddress';
import { requestGetFiltersLikeCategoriesList } from 'src/redux/categories/categoriesOperations/getFiltersLikeCategoriesList';
import getBannersSsr from 'src/swr/useBanners/helpers/getBannersSsr';
import MainPage, { Props } from '../../components/containers/MainPage/MainPage';
import getAllCategories from '../../redux/categories/categoriesOperations/getAllCategories';
import { NextPageContextWithStore } from '../../redux/initializeStore';
import { selectAppConfig } from '../../utils/appConfig/selectAppConfig';
import { getIsServer } from '../../utils/system/getIsServer';


const Index: NextPage<Props> = (props) => {
  return <MainPage {...props} />;
};

Index.getInitialProps = async (ctx: NextPageContextWithStore): Promise<Props> => {
  let banners: null | Banner[] = null;
  let promotions: Product[] = [];
  const isServer = getIsServer();

  if (isServer) {
    const { store: redux } = ctx;
    const dispatch = redux.dispatch;
    const state = redux.getState();
    const storeId = state.storeManagement.store.id;
    const { fetchConfig } = selectAppConfig(state);
    const { delivery: deliveryType } = selectAddress(state);

    const promises = [
      dispatch(getAllCategories(true)),
      dispatch(requestGetFiltersLikeCategoriesList()),
      getBannersSsr(storeId, fetchConfig),
      requestGetFiltersLikeCategoriesProducts({
        fetchConfig,
        storeId,
        categoryId: 'promotions',
        deliveryType,
        queryString: 'only_items=true',
      }),
    ];

    const [
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _categories, _filters,
      bannersFromRequest,
      promotionsCategory,
    ] = await Promise.all(promises);
    banners = bannersFromRequest as Banner[];
    promotions = (promotionsCategory as CategoryProducts).results;
  }

  return { bannersSsr: banners, promotionsSsr: promotions };
};

export default Index;
