import { Fragment, useEffect } from 'react';
import selectAddress from 'src/redux/cart/general/selectAddress';
import { useAppSelector } from 'src/redux/hooks';
import PopularProductsSlider from '../PopularProductsSlider/PopularProductsSlider';
import usePopularCategories from './helpers/usePopularCategories';

export interface Props {
  place: string;
}

export default function PopularCategoriesSliders(props: Props) {
  const { place } = props;

  const storeId = useAppSelector((s) => s.storeManagement.store.id);
  const deliveryType = useAppSelector(selectAddress).delivery;

  const {
    popularCategories,
    updatePopularCategories,
  } = usePopularCategories();

  useEffect(() => {
    updatePopularCategories(storeId, deliveryType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {popularCategories.map((category) => (
        <PopularProductsSlider
          categoryId={category.id}
          title={category.title}
          place={place}
          key={category.id}
        />
      ))}
    </Fragment>
  );
}
