import ProductsSliderWithPlaceholder from 'src/components/views/ProductsSliderWithPlaceholder/ProductsSliderWithPlaceholder';
import useProductsCarouselProps from 'src/hooks/useProductsCarouselProps/useProductsCarouselProps';
import { getCategoryUrl } from 'src/modules/Categories';
import { Product } from 'src/redux/apiTypes';
import { selectCartItems } from 'src/redux/cart/general/cartSelectors';
import selectAddress from 'src/redux/cart/general/selectAddress';
import { useAppSelector } from 'src/redux/hooks';
import { addToCartButtonsEnum, sendRemarketingCategoryProducts, sendViewProductsListEvent } from 'src/utils/marketing/enhancedEcommerce';
import mapProductsWithAmount from 'src/utils/product/mapProductsWithAmount';
import usePopularProducts from './helpers/usePopularProducts';

export interface Props {
  categoryId: string;
  title: string;
  /** @description Place that is used in the component itself */
  place: string;
}

/** @description Place that is used in handlers */
const handlersPlace = addToCartButtonsEnum.POPULAR_PRODUCTS_SLIDER;

function getSliced(items: Product[], needSlice: boolean): Product[] {
  return needSlice ? items.slice(0, 9) : items;
}

export default function PopularProductsSlider(props: Props) {
  const { categoryId, title, place } = props;
  const storeId = useAppSelector((s) => s.storeManagement.store.id);
  const deliveryType = useAppSelector(selectAddress).delivery;
  const cartItems = useAppSelector(selectCartItems);
  const language = useAppSelector((s) => s.app.language);

  const {
    loadingStatus,
    popularProductsData: { items, totalCount },
    updatePopularProducts,
  } = usePopularProducts({ deliveryType, storeId });

  const withShowAllButton = totalCount > 10;

  const carouselProps = useProductsCarouselProps({
    items: getSliced(items, withShowAllButton),
    place,
    title,
    handlersPlace,
  });

  const placeholderShouldBeDisplayed = loadingStatus === 'idle' || loadingStatus === 'loading';
  const sliderShouldNotDisplayed = loadingStatus === 'failed';

  const handleImpressed = async () => {
    const { items } = await updatePopularProducts(categoryId);
    sendRemarketingCategoryProducts(getSliced(items, withShowAllButton), place, categoryId);

    const productsWithAmount = mapProductsWithAmount(items, cartItems);
    sendViewProductsListEvent(categoryId, title, productsWithAmount);
  };

  return (
    <ProductsSliderWithPlaceholder
      placeholderTitle={title}
      dataMarker='Products Carousel'
      onImpressed={handleImpressed}
      sliderShouldNotDisplayed={sliderShouldNotDisplayed}
      placeholderShouldBeDisplayed={placeholderShouldBeDisplayed}
      carouselProps={{
        ...carouselProps,
        withShowAllButton,
        buttonUrl: getCategoryUrl({
          language,
          categoryId,
        }),
      }}
    />
  );
}
