import { useDispatch } from "react-redux";
import { ScheduleSelectInfoTextProps } from "src/components/views/ScheduleSelectInfoText/ScheduleSelectInfoText";
import getClosestSlot from "src/modules/ScheduleDelivery/helpers/getClosestSlot";
import useScheduleProps from "src/modules/ScheduleDelivery/hooks/useScheduleProps";
import selectAddress from "src/redux/cart/general/selectAddress";
import { useAppSelector } from "src/redux/hooks";
import { openModal } from "src/redux/modals";
import selectScheduleDeliveryProps from "src/swr/useScheduleDelivery/selectScheduleDeliveryProps";
import useScheduleDelivery from "src/swr/useScheduleDelivery/useScheduleDelivery";
import getDataStatus from "src/utils/system/getDataStatus";
import { useToggles } from "src/utils/toggles/useToggles";

const useSlotsWidgetProps = () => {
  const dispatch = useDispatch();
  const { NEW_YEAR_AVAILABLE: withNewYearIcon } = useToggles();

  const scheduleDeliveryProps = useAppSelector(selectScheduleDeliveryProps);
  const deliveryType = useAppSelector(selectAddress).delivery;
  const addressIsChecked = useAppSelector(s => s.addressManagement.addressIsChecked);
  const language = useAppSelector(s => s.app.language);
  const userIsLoggedIn = useAppSelector(s => s.user.isLoggedIn);
  const address = useAppSelector(selectAddress);

  const { scheduleDeliveryList,  isLoading: scheduleListIsFetching } = useScheduleDelivery(scheduleDeliveryProps);
  const { timeWindow } = useScheduleProps();
  const scheduleClosestSlot = getClosestSlot(scheduleDeliveryList);

  const timeWindowToShow = userIsLoggedIn ? timeWindow : scheduleClosestSlot;
  const addressIsEmpty = Object.keys(address).length === 0 && addressIsChecked;

  const scheduleNotLoaded = (!addressIsChecked && !scheduleDeliveryList.length);
  const isLoading = scheduleNotLoaded || scheduleListIsFetching;
  const dataStatusLoading = getDataStatus(isLoading);

  const showSlotsModal = () => {
    const noAvailableSlots = scheduleClosestSlot === null;

    if (noAvailableSlots) {
      dispatch(openModal('noAvailableSlotsModal'));
    } else if (scheduleDeliveryList && scheduleDeliveryList.length) {
      dispatch(openModal('availableDeliverySlotsModal'));
    }
  };

  const handleClick = () => {
    if (addressIsEmpty) {
      dispatch(openModal('addressModal'));
    } else {
      showSlotsModal();
    }
  };

  const scheduleTextProps: ScheduleSelectInfoTextProps = {
    deliveryType,
    language,
    timeWindow: timeWindowToShow,
    withTooltip: !!timeWindowToShow,
    withDeliveryPrefix: true,
  };

  return {
    handleClick,
    withNewYearIcon,
    scheduleTextProps,
    addressIsEmpty,
    dataStatusLoading,
    textIsAccented: !timeWindowToShow,
  };
};

export default useSlotsWidgetProps;
