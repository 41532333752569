import { Store } from 'src/redux/apiTypes';
import { RetailChain } from 'src/redux/retailChain/types';
import { Country } from 'src/utils/appConfig/getInitialAppConfig';
import socialNetworksByCountry from './socialNetworksByCountry';

interface Params {
  baseURL?: string;
  storeInfo: Store;
  retailChain: RetailChain | null;
  countryText: string;
  country: Country;
}

export default function getOrganizationMicroMarking(params: Params) {
  const { baseURL = '', storeInfo, retailChain, country, countryText } = params;

  if(storeInfo.id === 'default') {
    return {};
  }

  const { email, phones, address } = storeInfo;
  const { city, street,  building } = address || {};

  const chainName = retailChain?.name;
  const logoURL = retailChain?.logo?.app_main;
  const telephone = phones ? phones[0] : '';

  const socialNetworks = socialNetworksByCountry[country] ?
    { sameAs: socialNetworksByCountry[country] } : {};

  return {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": chainName,
    "url": baseURL,
    "logo": logoURL,
    "email": email,
    "telephone": telephone,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": `${ street }, ${ building }`,
      "addressLocality": `${ city }, ${ countryText }`,
      "postalCode": storeInfo.post_code,
    },
    "brand": {
      "@type": "Thing",
      "name": `Zakaz.${country}`,
    },
    ...socialNetworks,
  };
}
